<!--
 * @Description: 
 * @Author: weig
 * @Date: 2021-08-03 15:47:11
 * @LastEditors: weig
 * @LastEditTime: 2022-02-23 12:19:38
-->
<template>
 <div>
        <!-- 面包屑 begin -->
        <eup-crumbs icon="Flag" firstCrumbs="营销管理" secondCrumbs="横幅配置" />
        <!-- 面包屑 end -->
        <!-- 内容区域 begin -->
        <div class="container">
            <div class="handle-box">
              <template v-if="checkPermission([`api${state.VIEW_VERSION}:sales:salesbanner:getpage`,`api${state.VIEW_VERSION}:sales:salesbanner:add`,`api${state.VIEW_VERSION}:sales:salesbanner:batchsoftdelete`])">
                <el-form ref="state.query"  :inline="true" v-if="checkPermission([`api${state.VIEW_VERSION}:sales:salesredpack:getpage`])">
                 <template v-if="checkPermission([`api${state.VIEW_VERSION}:sales:salesbanner:getpage`])">
                    <el-form-item label="横幅名称">
                      <el-input v-model="state.bannerName" placeholder="横幅名称" ></el-input>
                    </el-form-item>
                    <el-form-item label="横幅状态">
                       <el-select v-model="state.query.isActive" filterable placeholder="横幅状态"  class="handle-select mr10">
                      <el-option v-for="item in state.queryOpisActive" :key="item.isActive" :label="item.label" :value="item.isActive"></el-option>
                  </el-select>
                    </el-form-item>
                    <el-form-item label="横幅类型">
                       <el-select v-model="state.queryType.BannerType" filterable placeholder="横幅类型"  class="handle-select mr10">
                      <el-option v-for="item in state.queryOpisActiveType" :key="item.BannerType" :label="item.label" :value="item.BannerType"></el-option>
                  </el-select>
                    </el-form-item>
                   <el-form-item el-form-item style="margin-right:10px">
                     <el-button type="primary" icon="Search" @click="handleSearch">搜索</el-button>
                   </el-form-item>
                  
                </template>
            <el-form-item el-form-item style="margin-right:10px">
                <el-button type="primary" icon="Plus" @click="handleClickAddData" v-if="checkPermission([`api${state.VIEW_VERSION}:sales:salesbanner:add`])">新增</el-button>
            </el-form-item>
            <el-form-item el-form-item style="margin-right:10px">
                <el-button
                  type="danger"
                  icon="Delete"
                  class="handle-del mr10"
                  @click="handleClickBatchDelete()"
                  v-if="checkPermission([`api${state.VIEW_VERSION}:sales:salesbanner:batchsoftdelete`])"
                >批量删除</el-button>
            </el-form-item>
            </el-form>
              </template>
            </div>
            <el-table
                :data="state.tableData"
                border
                highlight-current-row
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
                @selection-change="handleSelectionChange"
                v-loading="state.loading">
                <el-table-column type="selection" width="60" align="center"></el-table-column>
                <el-table-column prop="num" label="序号" width="60" align="center"></el-table-column>
                <el-table-column prop="bannerName" label="横幅名称" min-width="120" align="center"></el-table-column>
                <el-table-column prop="bannerType" label="横幅类型" min-width="80" align="center" :formatter="hasValidFormatType"></el-table-column>
                <el-table-column prop="bannerImg" label="横幅图片" min-width="120" align="center">
                <template min-width="90" style="display:block;" #default="scope">
                  <el-image style="width:80px;height:60px;border:none;" :src="scope.row.bannerImgUrl"/>
                </template>
                </el-table-column>
                <el-table-column prop="regionName" min-width="90" label="地区" align="center"></el-table-column>
                <el-table-column prop="startDate" label="开始时间" min-width="160" align="center"></el-table-column>
                <el-table-column prop="endDate" label="结束时间" min-width="160" align="center"></el-table-column>
                <el-table-column prop="isActive" label="是否有效" min-width="80" align="center" :formatter="hasValidFormatisA"></el-table-column>
               <el-table-column label="操作" min-width="220" align="center" fixed="right" v-if="checkPermission([`api${state.VIEW_VERSION}:sales:salesbanner:update`,`api${state.VIEW_VERSION}:sales:salesbanner:get`,`api${state.VIEW_VERSION}:sales:salesbanner:softdelete`])">
                  <template #default="scope">
                    <el-button
                    type="text"
                    icon="Edit"
                    @click="handleEdit(scope.row)"
                    v-if="checkPermission([`api${state.VIEW_VERSION}:sales:salesbanner:update`])"
                    >编辑</el-button>
                    <el-button
                        type="text"
                        icon="Check"
                        @click="handleClickDetails(scope.row)"
                        v-if="checkPermission([`api${state.VIEW_VERSION}:sales:salesbanner:get`])"
                    >查看详情</el-button>
                    <el-button
                        type="text"
                        icon="Delete"
                        class="red"
                        @click="handleClickDelete(scope.row)"
                        v-if="checkPermission([`api${state.VIEW_VERSION}:sales:salesbanner:softdelete`])"
                    >删除</el-button>
                  </template>
                </el-table-column>
            </el-table>
            <!-- 分页 begin -->
            <EupPagination
                :current-page="state.pageIndex"
                :pagesizes="[10,20,50,100]"
                :pagesize="state.pageSize"
                :total="state.pageTotal"
                @getPageData="datasource"
                @resPageData="resPageData">
            </EupPagination>
            <!-- 分页 end -->
        </div>
        <!-- 内容区域 end -->
        <!-- 添加/编辑窗口 begin -->
        <el-dialog 
          :title="state.dialogTitle"
          v-model="state.addDialogFormVisible"
          width="60%"
          :close-on-click-modal="false"
          @close="closeDialog">
            <el-form
                ref="refAddForm"
                :model="state.addForm"
                label-width="80px"
                :inline="false"         
              >
              <el-row>
                     <el-col >
                    <el-form-item label="时间选择" prop="remark">
                        <el-date-picker
                          v-model="state.time"
                          type="datetimerange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期">
                           </el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
                    <el-form-item label="横幅名称" prop="bannerName">
                      <el-input v-model="state.addForm.bannerName" autocomplete="off" />
                    </el-form-item>
                  </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
                    <el-form-item label="横幅链接" prop="bannerLink">
                      <el-input v-model="state.addForm.bannerLink" autocomplete="off" />
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
                    <el-form-item label="横幅序列" prop="sequence">
                      <el-input autocomplete="off" v-model.trim="state.addForm.sequence" oninput="value=value.replace(/^\.+|[^\d.]/g,'')"/>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
                    <el-form-item label="横幅类型" prop="bannerType">
                      <el-select v-model="state.addForm.bannerType" placeholder="请选择">
                            <el-option
                              v-for="item in state.opbannerType"
                              :key="item.bannerType"
                              :label="item.label"
                              :value="item.bannerType"
                            ></el-option
                          ></el-select>
                    </el-form-item>
                  </el-col>
                   <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
                    <el-form-item label="是否有效" prop="isActive">
                      <el-select v-model="state.addForm.isActive" placeholder="请选择">
                            <el-option
                              v-for="item in state.opisActive"
                              :key="item.isActive"
                              :label="item.label"
                              :value="item.isActive"
                            ></el-option
                          ></el-select>
                    </el-form-item>
                  </el-col>
                   <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
                    <el-form-item label="横幅描述" prop="remark">
                      <el-input v-model="state.addForm.remark" autocomplete="off" />
                    </el-form-item>
                  </el-col>
                   <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
                    <el-form-item label="省" prop="province">
                        <el-select v-model="state.province" placeholder="请选择" @change="changeProvince" :disabled=state.isEdit>
                        <el-option
                          v-for="item in state.regionOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6" v-if="(state.isEdit ? (state.province != 0 && state.city != '') : true)">
                    <el-form-item label="市" prop="city">
                        <el-select v-model="state.city" placeholder="请选择" :disabled=state.isEdit>
                        <el-option
                          v-for="item in state.cityOption"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-form-item label="图片" prop="Portrait" >
                        <el-upload action="#" :on-success="haderOnsuucess" list-type="picture-card" :on-change="handleChange" :on-remove="handleRemove" >
                            <img v-if="state.form.portraitUrl" :src="state.form.portraitUrl" style="width:100%;height:100%">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                <!-- </el-col> -->
              </el-row>
            </el-form>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="closeDialog()">取 消</el-button>
              <el-button type="primary" @click="addDialogFormSave(this)">确 定</el-button>
            </span>
          </template>
        </el-dialog>
        <!-- 添加/编辑窗口 end -->
        <!-- 详情弹出框 -->
        <el-dialog title="详情" v-model="state.detailsVisible" width="60%" :close-on-click-modal="false">
            <el-form ref="form" :model="state.particulars"  label-width="80px" :inline="false"    >
                  <el-row>
                      <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
                    <el-form-item label="横幅名称" prop="bannerName">
                      <el-input v-model="state.particulars.bannerName" autocomplete="off" :disabled="true" />
                    </el-form-item>
                  </el-col>
                   <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
                    <el-form-item label="横幅类型" prop="bannerName">
                      <el-input v-model="state.particulars.bannerType" autocomplete="off" :disabled="true" />
                    </el-form-item>
                  </el-col>
                   <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
                    <el-form-item label="横幅地区" prop="bannerName">
                      <el-input v-model="state.particulars.regionName" autocomplete="off" :disabled="true" />
                    </el-form-item>
                  </el-col>
                   <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
                    <el-form-item label="开始时间" prop="bannerName">
                      <el-input v-model="state.particulars.startDate" autocomplete="off" :disabled="true" />
                    </el-form-item>
                  </el-col>
                   <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
                    <el-form-item label="结束时间" prop="bannerName">
                      <el-input v-model="state.particulars.endDate" autocomplete="off" :disabled="true" />
                    </el-form-item>
                  </el-col>
                     <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
                    <el-form-item label="横幅链接" prop="bannerLink">
                      <el-input v-model="state.particulars.bannerLink" autocomplete="off" :disabled="true" />
                    </el-form-item>
                  </el-col>
                   <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
                    <el-form-item label="横幅序列" prop="bannerName">
                      <el-input v-model="state.particulars.sequence" autocomplete="off" :disabled="true" />
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
                    <el-form-item label="是否有效" prop="bannerName">
                      <el-input v-model="state.particulars.isActive" autocomplete="off" :disabled="true" />
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
                    <el-form-item label="横幅描述" prop="bannerName">
                      <el-input v-model="state.particulars.remark" autocomplete="off" :disabled="true" />
                    </el-form-item>
                  </el-col>
                  <el-form-item label="图片">
                    <el-image style="width: 100px; height: 100px" :src="state.particulars.bannerImgUrl"></el-image>
                </el-form-item>
                  </el-row>
                  
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import { reactive, onBeforeMount, onMounted ,ref} from 'vue'
import { getSelectList} from "@/serviceApi/system/areaManage"
import { SalesBannerBatchSoftDelete,SalesBannerGetPage,SalesBannerDelete,SalesBanneradd,SalesBannerUpdate} from "@/serviceApi/salesBanner/salesBanner"
import EupPagination from "../../../components/EupPagination.vue"
import { ElMessage,ElMessageBox } from 'element-plus'
import { elConfirmDialog,formatDate} from "@/common/js/comm"
import {postimg} from "@/serviceApi/Image/Image"
import EupCrumbs from "../../../components/eup-crumbs/index.vue"
import EnumConfig from "@/enum/EnumConfig"
var CURR_VIEW_VERSION = EnumConfig.EnumConfig.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var VIEW_VERSION = CURR_VIEW_VERSION == 'V0' ? '' : `:S:${CURR_VIEW_VERSION}`;
export default {
  name: '',
components: {
        EupPagination: EupPagination,
        EupCrumbs: EupCrumbs
    },
  setup() {
      const refAddForm = ref(null);
      const state = reactive({
      VIEW_VERSION: VIEW_VERSION,
        tableData:[],pageIndex:1,pageSize:10,bannerName:"",  loading: false,multipleSelection:[],frequency:0,
        deleteImgData:
        {
            isDelete: false,
            rowId: 0
        },
        queryOpisActive: [{isActive: null,label: '全部'},{isActive: '1',label: '有效'},{isActive: '2',label: '无效'}],
        query:{isActive: null},
        queryOpisActiveType: [{BannerType: null,label: '全部'},{BannerType: '1',label: '首页'},{BannerType: '2',label: '其他'}],
        queryType:{BannerType: null},
        dialogTitle:"",addDialogFormVisible:false,addForm:{bannerName:"",bannerLink:"",sequence:"",bannerType:'1',isActive:'1',},isEdit:false,
        //是否有效枚举集
        opisActive: [{isActive: '1',label: '有效'},{isActive: '2',label: '无效'}],
        opbannerType: [{bannerType: '1',label: '首页'},{bannerType: '2',label: '其他'}],
        province:"",
        regionOptions: [{value: 0,label: "全国"}],
        city: "",cityOption:[],time:'',form:{},detailsVisible:false,particulars:{}
    })
    onBeforeMount(() => {
    })
    onMounted(() => {
      datasource();
    })
    const datasource=()=>{
       var params = {
               "currentPage":state.pageIndex,
               "pageSize": state.pageSize,
               "filter.bannerId": "",
               "filter.bannerType": null,
               "filter.bannerName": state.bannerName,
               "filter.isActive": state.query.isActive,
               "filter.BannerType":state.queryType.BannerType
            }
            state.loading = true;
            SalesBannerGetPage(params).then(function(res){
              if(res.code == 1){
                state.pageTotal = res.data.total;//初始化列表数据总数
                state.tableData = res.data.list;
                //添加num序号字段
                state.tableData.forEach((data, i) => {
                    data.num = i + 1;
                });
              } else {
                ElMessage.error(res.msg);   
              }
              state.loading = false;
            });
    };
    const handleSearch=()=>{
        state.pageIndex=1
        datasource()
      
    };
    const handleClickAddData=()=>{
          state.isEdit = false;
          state.addDialogFormVisible = true;
          state.dialogTitle = "新增横幅配置";
          state.addForm.add="add";
          getSelectList_Area(0);
    };
    const handleSelectionChange=(val)=>{
      state.multipleSelection=  val.map(s =>{
              return s.bannerId;
        });
    };
    const handleClickBatchDelete=()=>{
       if(state.multipleSelection.length==0){
        return  ElMessage.error("请选择要删除的数据");
       }
           elConfirmDialog(ElMessageBox,'此操作将永久删除该数据, 是否继续?','提示', '', ()=>{
            SalesBannerBatchSoftDelete(state.multipleSelection).then(res =>{
                if (res.code == 1){
                    ElMessage.success("删除成功");
                    datasource();
                } else {
                    ElMessage.error("删除失败！");
                }
            });
        }, ()=>{
            ElMessage.info("取消删除！");
        });
    };
    const resPageData = (obj) =>{
            state.pageIndex = obj.currPage;
            state.pageSize = obj.pageSize;
        }
    const handleEdit=(row)=>{
      var strJson = JSON.stringify(row);
          var jsonRow = JSON.parse(strJson)
          state.dialogTitle = "编辑横幅配置";
           state.addForm.eit="eit";
          state.addDialogFormVisible = true;
          state.province = "";
          state.city = "";
           state.addForm = {
            "bannerId":jsonRow.bannerId,
            "regionId": jsonRow.regionId,
            "bannerName":jsonRow.bannerName,
            "bannerLink":jsonRow.bannerLink,
            "isActive":jsonRow.isActive + '',
            "bannerType":jsonRow.bannerType + '',
            "sequence":jsonRow.sequence,
            "remark":jsonRow.remark,
          }
          state.time=[jsonRow.startDate,jsonRow.endDate]
           state.form.portraitUrl=jsonRow.bannerImgUrl
           state.form.portrait =jsonRow.bannerImg
           getSelectList_Area(0, ()=>{
            if (jsonRow.regionFullId == undefined || jsonRow.regionFullId == null || jsonRow.regionFullId == ""){
              state.province = 0;
            } else {
              state.province = jsonRow.regionFullId.split(',')[0] == 0 ? jsonRow.regionId : jsonRow.regionFullId.split(',')[0];
            }
            if (jsonRow.regionId != "" && jsonRow.regionFullId != "" && jsonRow.regionFullId != "0"){
              getCity(jsonRow.regionFullId, ()=>{
                if (jsonRow.regionId == undefined || jsonRow.regionId == null || jsonRow.regionId == ""){
                  state.city = "";
                } else {
                  state.city = jsonRow.regionId;
                }
              });
            }
          }); 
    };
    const handleClickDetails=(row)=>{
        state.detailsVisible=true;
        var strJson = JSON.stringify(row);
        state.particulars= JSON.parse(strJson)
        state.particulars.bannerType= hasValidFormatType(state.particulars)
       state.particulars.isActive= hasValidFormatisA(state.particulars)
    };
    const handleClickDelete=(row)=>{
      elConfirmDialog(ElMessageBox,'此操作将永久删除该数据, 是否继续?','提示', '', ()=>{
            SalesBannerDelete(row.bannerId).then(res =>{
                if (res.code == 1){
                    ElMessage.success("删除成功");
                    datasource();
                } else {
                    ElMessage.error("删除失败！");
                }
            });
        }, ()=>{
            ElMessage.info("取消删除！");
        });
    };
    const hasValidFormatType=(row)=>{
          if (row.bannerType == 1){
            return "首页";
          } else if (row.bannerType == 2){
            return "其他";
          }else{
              return "全部";
          }
        };
        const hasValidFormatisA=(row)=>{
          if (row.isActive == 1){
            return "有效";
          } else if (row.isActive == 2){
            return "无效";
          }else{
              return "全部";
          }
        };
          //关闭对话框
        const closeDialog = ()=>{
           state.addDialogFormVisible = false;
           state.form.portraitUrl="";
           state.province = "";
                   state.city = "";
                  state.cityOption = [];
                  state.time=[];
               state.addForm={bannerName:"",bannerLink:"",sequence:"",bannerType:'1',isActive:'1',}
        };
        //切换省份/地级市
        const changeProvince = (val)=>{
          getCity(val);
          state.city = "";
        }
         const getCity = (pregionId,callback)=>{
          state.cityOption = [];
          //通过省地区ID去后台获取子城市
          getSelectList(pregionId).then((res)=>{
            if (res.code == 1){
              res.data.forEach(function(cityData, cityIndex){
                var subJson = {};
                subJson.value = cityData.regionId;
                subJson.label = cityData.regionName;
                state.cityOption.push(subJson);//添加二级菜单子城市
              });
              if (callback){
                callback();
              }
            }else {
              ElMessage.error(res.msg);
            }
          }).catch((err)=>{
            ElMessage.error(err.msg);
          });
        }
        const getSelectList_Area = (regionId, callback)=>{
          state.wholeCountry = [];//清空历史数据
          //state.regionOptions = [{value: 0,label: "全国"}];
          //先判断全国第一级下拉菜单是否为空，如果为空要调用接口获取
          getSelectList(regionId).then((res)=>{
            if (res.code == 1){
              state.wholeCountry = res.data;
             res.data.forEach(function(data, index){
                var subJson = {};
                subJson.value = data.regionId;
                subJson.label = data.regionName;
                state.regionOptions.push(subJson);//添加一级下拉菜单
             });
              if (callback){
                callback();
              }
            }else {
              ElMessage.error(res.msg);
            }
          }).catch((err)=>{
            ElMessage.error(err.msg)
          });       
        }
        const addDialogFormSave=()=>{
            var params={}
          if(state.dialogTitle =="新增横幅配置" && state.addForm.add=="add"){
             params = {
              "startDate":formatDate(state.time[0]),
              "endDate":formatDate(state.time[1]),
              "regionId": state.city ? state.city : (state.province ? state.province : 0),
               "isActive": state.addForm.isActive ? state.addForm.isActive : 1,
               "remark": state.addForm.remark,
                "bannerImg": state.form.portrait,
               "bannerName": state.addForm.bannerName,
               "bannerType": state.addForm.bannerType ? state.addForm.bannerType : 1,
               "bannerLink": state.addForm.bannerLink,
               "sequence":state.addForm.sequence,
            }

            if(params.startDate=="NaN-NaN-NaN NaN:NaN:NaN"||params.endDate=="NaN-NaN-NaN NaN:NaN:NaN"){
                return ElMessage.error("请选择日期");
            }
            if(params.bannerName==""){
              return ElMessage.error("请完善信息");
            }
             if(params.bannerLink==""){
              return ElMessage.error("横幅链接不能为空");
            }
              if(params.sequence==""){
              return ElMessage.error("请完善信息");
            }
           SalesBanneradd(params).then((res)=>{
               if (res.code == '1'){
                    ElMessage.success("添加成功");
                    datasource();
                    state.addDialogFormVisible = false;
                    state.form.portrait="";
                    state.province = "";
                   state.city = "";
                  state.cityOption = [];
                  state.time=[];
                } else {
                    ElMessage.error(res.msg);
                }
            });
          }
          else{
             params = {
              "bannerId":state.addForm.bannerId,
              "startDate":formatDate(state.time[0]),
              "endDate":formatDate(state.time[1]),
              "regionId": state.city ? state.city : (state.province ? state.province : 0),
              "isActive": state.addForm.isActive ? state.addForm.isActive : 1,
              "remark": state.addForm.remark,
              "bannerImg": state.form.portrait,
              "bannerName": state.addForm.bannerName,
              "bannerType": state.addForm.bannerType ? state.addForm.bannerType : 1,
              "bannerLink": state.addForm.bannerLink,
              "sequence":state.addForm.sequence,
            }
           
            if(params.startDate=="NaN-NaN-NaN NaN:NaN:NaN"||params.endDate=="NaN-NaN-NaN NaN:NaN:NaN"){
                return ElMessage.error("请选择日期");
            }
               if(params.bannerLink==""){
              return ElMessage.error("横幅链接不能为空");
            }
              if(params.sequence==""){
              return ElMessage.error("请完善信息");
            }
               if(params.bannerName==""){
              return ElMessage.error("请完善信息");
            }
            SalesBannerUpdate(params).then((res)=>{
                 if (res.code==1){
                    ElMessage.success("编辑成功");
                    datasource();
                    state.form.portrait="";
                    state.addDialogFormVisible = false;
                } else {
                    ElMessage.error(res.msg);
                }
            }); 
          }
        };
     //图片 begin
        //图片上传成功回调
        const haderOnsuucess=(response, file, fileList)=> {
            console.log(response, file, fileList);
        }
        const handleRemove=(file, fileList)=> {
            state.hideUpload = fileList.length >= state.limitCount;
            state.form.portraitUrl="";
        }
        const handleChange=(file, fileList)=> {
            state.hideUpload = fileList.length >= state.limitCount;
            state.frequency +=1;
            if (state.frequency%2 === 0) {
                var formData = new FormData();
            formData.append('File', file.raw);
            formData.append('FileCategory', 'Avatar');
            postimg(formData).then(function(res) {
                state.form.portrait = res.data.eurl;
                state.form.portraitUrl = res.data.url;
            });
            } else {
                       
              }
        }
        //图片 end
    return {
      state,datasource,handleSearch,handleClickAddData,handleSelectionChange,handleClickBatchDelete,resPageData,
      handleEdit,handleClickDetails,handleClickDelete,hasValidFormatType,hasValidFormatisA,closeDialog,refAddForm,
      changeProvince,getCity,getSelectList_Area,addDialogFormSave,haderOnsuucess,handleRemove,handleChange
    }
  },
}
</script>
<style scoped>
</style>