/*
 * @Description: 
 * @Author: weig
 * @Date: 2021-09-27 12:03:31
 * @LastEditors: weig
 * @LastEditTime: 2021-12-17 11:33:14
 */
import request from '../../utils/request'
import EnumConfig from '../../enum/EnumConfig'

var CURR_API_VERSION = EnumConfig.EnumConfig.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var API_VERSION = CURR_API_VERSION == 'V0' ? '' : `/S/${CURR_API_VERSION}`;
//查询单条
export const  SalesBannerGet=(params)=>{
    return request.apiGet(`/Api${API_VERSION}/Sales/SalesBanner/Get`,params)
}
//横幅获取分页
export const  SalesBannerGetPage=(params)=>{
    return request.apiGet(`/api${API_VERSION}/Sales/SalesBanner/GetPage`,params)
}
//横幅删除
export const SalesBannerDelete=(params)=>{
    return request.apiDelete(`/api${API_VERSION}/Sales/SalesBanner/SoftDelete?bannerId=`+params) 
}
//横幅批量删除
export const SalesBannerBatchSoftDelete=(params)=>{
    return request.apiPut(`/api${API_VERSION}/Sales/SalesBanner/BatchSoftDelete`,params)
}
//横幅新增
export const SalesBanneradd=(params)=>{
    return request.apiPost(`/api${API_VERSION}/Sales/SalesBanner/Add`,params)
}
//横幅修改
export const SalesBannerUpdate=(params)=>{
    return request.apiPut(`/api${API_VERSION}/Sales/SalesBanner/Update`,params)
}